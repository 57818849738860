import React from "react";
import { Box, Paper, Typography } from "@mui/material";

interface Parameters {
  // Empty for now, but keeping the interface for future parameters
}

export interface PayproInlineCheckoutProps {
  parameters: Parameters;
  projectId?: string;
  onChange: (param: string, value: unknown) => void;
}

const PayproInlineCheckout: React.FC<PayproInlineCheckoutProps> = () => {
  return (
    <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography>Paypro Inline Checkout</Typography>
      </Box>
    </Paper>
  );
};

export default PayproInlineCheckout;
