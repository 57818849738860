import { useEffect, useState, useCallback } from "react";
import { User } from "firebase/auth";
import {
  Button,
  Select,
  FormControl,
  InputLabel,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Backdrop
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Box, CircularProgress } from "@mui/material";
import { AutoTest, Funnel, Paywall } from "../../types";
import { deleteDoc, doc, getDocs, query, setDoc } from "firebase/firestore";
import { collection } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { nanoid } from "nanoid";

interface AutotestingProps {
  user: User;
  projectId: string;
  funnels: Funnel[];
  paywalls: Paywall[];
}

const Autotesting = ({
  user,
  projectId,
  funnels,
  paywalls
}: AutotestingProps) => {
  const notistack = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [autoTests, setAutoTests] = useState<AutoTest[]>([]);
  const [open, setOpen] = useState(false);
  const [newAutoTest, setNewAutoTest] = useState<Partial<AutoTest>>({
    funnel_id: "",
    paywall_ids: []
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = async () => {
    setOpen(false);
    console.log("newAutoTest", newAutoTest);

    if (user && projectId) {
      const db = getFirestore();
      try {
        setLoading(true);
        console.log("useruid, projectId", user.uid, projectId);
        await setDoc(
          doc(
            db,
            "projects",
            user.uid,
            "projects",
            projectId,
            "autotests",
            nanoid()
          ),
          {
            funnel_id: newAutoTest.funnel_id,
            paywall_ids: newAutoTest.paywall_ids
          }
        );
        handleClose();
        syncAutoTests(user, projectId);
        setNewAutoTest({
          funnel_id: "",
          paywall_ids: []
        });
        notistack.enqueueSnackbar("Auto test added", { variant: "success" });
      } catch (error) {
        notistack.enqueueSnackbar("Error adding auto test", {
          variant: "error"
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteAutoTest = useCallback(
    async (autoTestId: string) => {
      if (user && projectId) {
        const db = getFirestore();
        try {
          setLoading(true);
          await deleteDoc(
            doc(
              db,
              "projects",
              user.uid,
              "projects",
              projectId,
              "autotests",
              autoTestId
            )
          );
          syncAutoTests(user, projectId);
          notistack.enqueueSnackbar("Auto test deleted", {
            variant: "success"
          });
        } catch (error) {
          notistack.enqueueSnackbar("Error deleting auto test", {
            variant: "error"
          });
        } finally {
          setLoading(false);
        }
      }
    },
    [user, projectId]
  );

  const syncAutoTests = async (user: User, projectId: string) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const autoTestsRef = collection(
        db,
        "projects",
        user.uid,
        "projects",
        projectId,
        "autotests"
      );
      const q = query(autoTestsRef);
      const querySnapshot = await getDocs(q);

      const autoTests = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id
      }));
      setAutoTests(autoTests as AutoTest[]);
      setLoading(false);
    } catch (error) {
      setAutoTests([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    syncAutoTests(user, projectId);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "24px"
        }}>
        Select onboarding & list of paywalls to test
      </Box>

      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
        onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {autoTests.map((autoTest, index) => (
        <Box
          key={index}
          id={`funnel-${index}`}
          sx={{ mt: 2, display: "flex", gap: 2, flexDirection: "row" }}>
          <Box
            sx={{ display: "flex", gap: 2, flexGrow: 1, alignItems: "center" }}>
            <span>Test {index + 1}</span>
            <Select
              id={`auto-select-funnel-${index}`}
              sx={{ height: 40, minWidth: 200 }}
              value={autoTest.funnel_id}
              readOnly>
              {funnels.map((funnel) => (
                <MenuItem key={funnel.id} value={funnel.id}>
                  {funnel.name}
                </MenuItem>
              ))}
            </Select>
            <Select
              id={`auto-select-paywall-${index}`}
              sx={{ height: 40, minWidth: 200 }}
              multiple
              readOnly
              value={autoTest.paywall_ids || []}
              renderValue={(selected) => {
                const selectedPaywalls = paywalls
                  .filter((p) => p.id && (selected as string[]).includes(p.id))
                  .map((p) => p.name ?? "")
                  .join(", ");
                return selectedPaywalls || "Select paywalls";
              }}>
              {paywalls.map((paywall) => (
                <MenuItem key={paywall.id} value={paywall.id}>
                  {paywall.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Fab
            color="secondary"
            aria-label="delete"
            onClick={() => deleteAutoTest(autoTest.id || "")}
            size="medium">
            <DeleteIcon />
          </Fab>
        </Box>
      ))}
      <Fab
        color="primary"
        aria-label="add"
        onClick={handleClickOpen}
        sx={{ margin: "35px auto" }}>
        <AddIcon />
      </Fab>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Auto Test</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            minWidth: 400
          }}>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="funnel-select-label">Funnel</InputLabel>
            <Select
              labelId="funnel-select-label"
              sx={{ height: 40 }}
              label="Funnel"
              value={newAutoTest.funnel_id}
              onChange={(e) =>
                setNewAutoTest({ ...newAutoTest, funnel_id: e.target.value })
              }>
              {funnels.map((funnel) => (
                <MenuItem key={funnel.id} value={funnel.id}>
                  {funnel.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="paywall-select-label">Paywall(s)</InputLabel>
            <Select
              labelId="paywall-select-label"
              sx={{ height: 40, alignItems: "center" }}
              multiple
              value={newAutoTest.paywall_ids || []}
              label="Paywalls"
              onChange={(e) =>
                setNewAutoTest({
                  ...newAutoTest,
                  paywall_ids: e.target.value as string[]
                })
              }>
              {paywalls.map((paywall) => (
                <MenuItem key={paywall.id} value={paywall.id}>
                  {paywall.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button sx={{ height: 40 }} onClick={handleClose}>
            Cancel
          </Button>
          <Button sx={{ height: 40 }} onClick={handleCreate}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Autotesting;
