import { useEffect, useState, useCallback } from "react";
import { User } from "firebase/auth";
import {
  Button,
  Select,
  FormControl,
  InputLabel,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Backdrop,
  TextField,
  Typography,
  Chip
} from "@mui/material";
import Card from "@mui/material/Card";

import { useSnackbar } from "notistack";
import { Box, CircularProgress } from "@mui/material";
import { ABTest, Funnel, Paywall } from "../../types";
import { deleteDoc, doc, getDocs, query, setDoc } from "firebase/firestore";
import { collection } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { nanoid } from "nanoid";

interface ABtestingProps {
  user: User;
  projectId: string;
  funnels: Funnel[];
  paywalls: Paywall[];
}

const ABtesting = ({ user, projectId, funnels, paywalls }: ABtestingProps) => {
  const notistack = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [abTests, setAbTests] = useState<ABTest[]>([]);
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [newAbTest, setNewAbTest] = useState<Partial<ABTest>>({
    value: "",
    variants: [
      {
        funnel_id: "",
        paywall_id: ""
      }
    ]
  });

  const handleClickOpen = () => {
    setNewAbTest({
      value: "",
      variants: [
        {
          funnel_id: "",
          paywall_id: ""
        }
      ]
    });
    setOpen(true);
  };

  const handleEditABTest = (id: string) => {
    const abTest = abTests.find((abTest) => abTest.id === id);
    if (abTest) {
      setNewAbTest(abTest);
      setEditing(true);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setEditing(false);
  };

  const handleClickOpenNewVariant = () => {
    setNewAbTest((prevState) => ({
      ...prevState,
      variants: [
        ...(prevState.variants || []),
        { funnel_id: "", paywall_id: "" }
      ]
    }));
  };

  const handleCreate = async () => {
    setOpen(false);

    if (user && projectId) {
      const db = getFirestore();
      try {
        setLoading(true);
        // console.log("useruid, projectId", user.uid, projectId);
        await setDoc(
          doc(
            db,
            "projects",
            user.uid,
            "projects",
            projectId,
            "abtests",
            newAbTest.id ? newAbTest.id : nanoid() /// edit or creation new
          ),
          {
            value: newAbTest.value,
            variants: newAbTest.variants
          }
        );
        handleClose();
        syncABTests(user, projectId);
        setNewAbTest({
          value: "",
          variants: [
            {
              funnel_id: "",
              paywall_id: ""
            }
          ]
        });
        const message = editing ? "AB test updated" : "AB test added";
        notistack.enqueueSnackbar(message, { variant: "success" });
      } catch (error) {
        notistack.enqueueSnackbar("Error adding AB test", {
          variant: "error"
        });
      } finally {
        setEditing(false);
        setLoading(false);
      }
    }
  };

  const deleteABTest = useCallback(
    async (abTestId: string) => {
      if (user && projectId) {
        const db = getFirestore();
        try {
          setLoading(true);
          await deleteDoc(
            doc(
              db,
              "projects",
              user.uid,
              "projects",
              projectId,
              "abtests",
              abTestId
            )
          );
          syncABTests(user, projectId);
          notistack.enqueueSnackbar("AB test deleted", {
            variant: "success"
          });
        } catch (error) {
          notistack.enqueueSnackbar("Error deleting AB test", {
            variant: "error"
          });
        } finally {
          setLoading(false);
        }
      }
    },
    [user, projectId]
  );

  const syncABTests = async (user: User, projectId: string) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const abTestsRef = collection(
        db,
        "projects",
        user.uid,
        "projects",
        projectId,
        "abtests"
      );
      const q = query(abTestsRef);
      const querySnapshot = await getDocs(q);

      const abTests = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id
      }));
      setAbTests(abTests as ABTest[]);
      setLoading(false);
    } catch (error) {
      setAbTests([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    syncABTests(user, projectId);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "24px"
        }}>
        Set A/B tests' bundles
      </Box>

      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
        onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {abTests.map((abTest, index) => (
        <Card
          key={index}
          id={`funnel-${index}`}
          sx={{
            mt: 2,
            display: "flex",
            gap: 2,
            flexDirection: "row",
            maxWidth: 700,
            padding: 2
          }}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: "column"
            }}>
            <span style={{ fontSize: 14, fontWeight: "bold", color: "black" }}>
              Test {index + 1}
            </span>
            <span style={{ fontSize: 14, color: "black" }}>
              URL param:{" "}
              <span style={{ fontWeight: 300, color: "gray" }}>
                ...thium.one/quiz?{" "}
              </span>
              <Typography
                fontSize={20}
                fontWeight={500}
                color="black"
                component="span"
                style={{ display: "inline" }}>
                {abTest.value ? abTest.value : "⚠️UNDEFINED"}
              </Typography>
              <span style={{ fontWeight: 300, color: "gray" }}>
                {" "}
                &utm_camp...
              </span>
            </span>
            {abTest.variants.map((variant, index) => (
              <Box
                key={`variant-${index}`}
                sx={{
                  display: "flex",
                  gap: 2
                }}>
                <p style={{ fontSize: 14, color: "black" }}>
                  Variant {index + 1}
                </p>
                <Chip
                  key={index}
                  variant="outlined"
                  id={`abtest-select-funnel-${index}`}
                  sx={{ height: 40, minWidth: 200 }}
                  label={
                    funnels.find((funnel) => funnel.id === variant.funnel_id)
                      ?.name || ""
                  }
                />

                <Chip
                  variant="outlined"
                  id={`ab-select-paywall-${index}`}
                  sx={{ height: 40, minWidth: 200 }}
                  label={
                    paywalls.find(
                      (paywall) => paywall.id === variant.paywall_id
                    )?.name || ""
                  }
                />
              </Box>
            ))}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Fab
              color="secondary"
              aria-label="delete"
              onClick={() => deleteABTest(abTest.id || "")}
              size="medium">
              <DeleteIcon />
            </Fab>
            <Fab
              color="secondary"
              aria-label="edit"
              onClick={() => handleEditABTest(abTest.id || "")}
              size="medium">
              <EditIcon />
            </Fab>
          </Box>
        </Card>
      ))}
      <Fab
        color="primary"
        aria-label="add"
        onClick={handleClickOpen}
        sx={{ margin: "35px auto" }}>
        <AddIcon />
      </Fab>

      {/* Adding / editing  A/B Test */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editing ? "Edit" : "Add"} new A/B Test</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            minWidth: 600
          }}>
          <FormControl fullWidth sx={{ mt: 2, marginBottom: 2 }}>
            <TextField
              label="A/B url"
              sx={{
                height: 40,
                "& .MuiInputBase-input": {
                  backgroundColor: "white"
                }
              }}
              color="primary"
              value={newAbTest.value}
              onChange={(e) =>
                setNewAbTest({ ...newAbTest, value: e.target.value })
              }
            />
          </FormControl>
          {newAbTest?.variants?.map((variant, index) => (
            <>
              <p
                style={{
                  fontSize: 14,
                  color: "black",
                  marginBottom: -20,
                  marginTop: 0
                }}>
                Variant {index + 1}
              </p>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="funnel-select-label">Funnel</InputLabel>
                  <Select
                    labelId="funnel-select-label"
                    sx={{ height: 40 }}
                    label="Funnel"
                    value={variant.funnel_id}
                    onChange={(e) => {
                      const updatedVariants = newAbTest?.variants?.map(
                        (v, i) => {
                          if (index === i) {
                            return {
                              ...v,
                              funnel_id: e.target.value as string
                            };
                          }
                          return v;
                        }
                      );

                      setNewAbTest({
                        ...newAbTest,
                        variants: updatedVariants
                      });
                    }}>
                    {funnels.map((funnel) => (
                      <MenuItem key={funnel.id} value={funnel.id}>
                        {funnel.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="paywall-select-label">Paywall(s)</InputLabel>
                  <Select
                    labelId="paywall-select-label"
                    sx={{ height: 40 }}
                    label="Paywall(s)"
                    value={variant.paywall_id}
                    onChange={(e) => {
                      const updatedVariants = newAbTest?.variants?.map(
                        (v, i) => {
                          if (index === i) {
                            return {
                              ...v,
                              paywall_id: e.target.value as string
                            };
                          }
                          return v;
                        }
                      );

                      setNewAbTest({
                        ...newAbTest,
                        variants: updatedVariants
                      });
                    }}>
                    {paywalls.map((paywall) => (
                      <MenuItem key={paywall.id} value={paywall.id}>
                        {paywall.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </>
          ))}
          <Fab
            color="primary"
            aria-label="add"
            onClick={handleClickOpenNewVariant}
            sx={{ margin: "35px auto" }}>
            <AddIcon />
          </Fab>
        </DialogContent>
        <DialogActions>
          <Button sx={{ height: 40 }} onClick={handleClose}>
            Cancel
          </Button>
          <Button sx={{ height: 40 }} onClick={handleCreate}>
            {editing ? "Save" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ABtesting;
